<script>
export default {
    name: "CookiesMixin",
    methods: {
        getCookieValue(key, isBoolean = true, defaultValue = true) {
            if (this.$cookies.isKey(key)) {
                const value = this.$cookies.get(key);
                return isBoolean
                    ? value == 'true'
                    : value
            } else {
                return defaultValue;
            }
        },

        setCookieValue(key, value) {
            this.$cookies.set(key, value);
        },

        removeCookieValue(key) {
            this.$cookies.remove(key);
        }
    }
}
</script>